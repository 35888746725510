<script lang="ts">

import GeneralGateway from '@/infra/gateway/generalGateway';
import { inject } from 'vue';
import { isMobile } from '@/services/Mobile';
import { Processing } from '@/Types/General';
import { getOffset } from '@/services/Browser';
import FormularioCalculadoraStep1 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep1.vue';
import FormularioCalculadoraStep2 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep2.vue';
import FormularioCalculadoraStep3 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep3.vue';
import FormularioCalculadoraStep4 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep4.vue';
import FormularioCalculadoraStep5 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep5.vue';
import FormularioCalculadoraStep6 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep6.vue';
import FormularioCalculadoraStep7 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep7.vue';
import FormularioCalculadoraStep8 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep8.vue';
import FormularioCalculadoraStep9 from '@/Components/Formularios/Calculadora/FormularioCalculadoraStep9.vue';
import { CalculadoraStep1Form, CalculadoraStep2Form, CalculadoraStep3Form, CalculadoraStep4Form, CalculadoraStep5Form, CalculadoraStep6Form, CalculadoraStep7Form, CalculadoraStep8Form, CalculadoraStep9Form } from '@/Types/Calculadora';
import Loading from '@/Components/Loading.vue'
import SubmitButton from '@/Components/SubmitButton.vue';
 
type FORM_TYPES =  CalculadoraStep1Form | CalculadoraStep2Form | CalculadoraStep3Form | CalculadoraStep4Form | CalculadoraStep5Form | CalculadoraStep6Form | CalculadoraStep7Form | CalculadoraStep8Form | CalculadoraStep9Form

export default {
    name: "Calculadora",
    props: {
        tipo: {type: String, required: true},
        maxSteps: {type: Number, required: true},
    },
    components: {
        FormularioCalculadoraStep1,
        FormularioCalculadoraStep2,
        FormularioCalculadoraStep3,
        FormularioCalculadoraStep4,
        FormularioCalculadoraStep5,
        FormularioCalculadoraStep6,
        FormularioCalculadoraStep7,
        FormularioCalculadoraStep8,
        FormularioCalculadoraStep9,

        SubmitButton,
        Loading,
    },
    setup() {
        return {
            generalGateway: inject<GeneralGateway>('generalGateway')!,
        }
    },
    data() { 
        let currentStep = 1

        const calculadoraStep1Form = new CalculadoraStep1Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any,
        )

        const calculadoraStep2Form = new CalculadoraStep2Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep3Form = new CalculadoraStep3Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep4Form = new CalculadoraStep4Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep5Form = new CalculadoraStep5Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep6Form = new CalculadoraStep6Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep7Form = new CalculadoraStep7Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep8Form = new CalculadoraStep8Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        const calculadoraStep9Form = new CalculadoraStep9Form(
            //@ts-ignore
            this.generalGateway.postCalculadora,
            (any:any)=>any
        )

        calculadoraStep1Form.loadInstance()
        calculadoraStep2Form.loadInstance()
        calculadoraStep3Form.loadInstance()
        calculadoraStep4Form.loadInstance()
        calculadoraStep5Form.loadInstance()
        calculadoraStep6Form.loadInstance()
        calculadoraStep7Form.loadInstance()
        calculadoraStep8Form.loadInstance()
        calculadoraStep9Form.loadInstance()
        
        return {
            processing : new Processing(),

            getOffset,

            mobile: isMobile(),

            calculadoraStep1Form,
            calculadoraStep2Form,
            calculadoraStep3Form,
            calculadoraStep4Form,
            calculadoraStep5Form,
            calculadoraStep6Form,
            calculadoraStep7Form,
            calculadoraStep8Form,
            calculadoraStep9Form,

            currentStep,
            isLoading: false,
            isRating: false,

            progress: 0,

            error: {},
        };
    },
    emits: ['goToProjeto', 'backToStart'],
    methods: {
        async getHashRating() {
           const response = await this.generalGateway.getHashRatingCalculadora();
        },
        async goToNextStep() {
            if (this.currentStep >= this.maxSteps) return;
            this.currentStep ++;

            if (this.currentStep == this.maxSteps) {
                this.isRating = true;
                this.isLoading = true;
                setTimeout(() => {
                    this.getProgressBar();
                    this.isLoading = false;
                }, 2000)
                if (this.tipo == 'projeto') {
                    await this.getHashRating();
                }
            }
        },
        goToPreviousStep() {
            if (this.currentStep <= 1) {
                this.backToStart()
            }
            this.currentStep --;
        },
        canSubmit() {
            return !this.processing.isBusyOrSetBusy();  
        },
        async handleSubmit(event: SubmitEvent, form: FORM_TYPES) {
            if(!this.canSubmit()) return;
            form.params = {
                'tipo': this.tipo,
                'step': this.currentStep,
            }
            const response = await form.save(event, this.resetSubmit, false, false, false)
            if (!response.success) {
                this.error = response;
                return;
            }
            this.error = {};
            this.goToNextStep()
        },
        resetSubmit(success: boolean) {
            this.processing.setValue(false);
            if (!success) return;
        },
        goToProjeto() {
            this.$emit('goToProjeto')
            this.isRating = false;
            this.currentStep = 5;
            this.generalGateway.postCalculadoraTrocarTipo()

        },
        backToStart() {
            this.$emit('backToStart')
        },
        getProgressBar() {
            let i = 0;
            const interval = setInterval(() => {
                if (i <= 85) {
                    this.progress = i;
                    i++;
                } else {
                    clearInterval(interval);
                }
            }, 10); // Ajuste o tempo conforme necessário
            }
    },
    computed: {
        progressBar() {
            return `width: ${this.progress}%; transition: .7s ease-out;`;
        }
    },
    async mounted(){
        this.currentStep = 1;
    }
}

</script>

<template>
    <section class="w-full my-[2rem]">
        <section class="ctn home-container" :class="mobile ? 'min-h-screen py-[7rem]' : 'min-h-[calc(100vh-7rem)] py-[7rem]'">
            <div>
                <template v-if="!isRating">
                    <div class="flex flex-col gap-[3rem] max-w-6xl m-auto">
                        <h3 class="font-medium text-[3rem]">Calculadora</h3>
                            
                            <div class="flex">
                                <div class="w-full light-grey-bg h-2 rounded-2xl">
                                    <div class="dark-blue-bg mt-[-.3rem] h-4 rounded-2xl" :style="`width:${(currentStep/(maxSteps+1))*100}%; transition:.7s ease-in;`"></div>
                                </div>
                            </div>
                            
                        <form v-if="currentStep == 1" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep1Form)" action="POST" enctype='multipart/form-data'>

                            <FormularioCalculadoraStep1
                                :calculadora="calculadoraStep1Form.instance"
                                :editable="calculadoraStep1Form.isEditable()"
                                :maxSteps="maxSteps"
                            />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                        </form>
                        <form v-else-if="currentStep == 2" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep2Form)" action="POST" enctype='multipart/form-data'>

                            <FormularioCalculadoraStep2
                                :calculadora="calculadoraStep2Form.instance"
                                :editable="calculadoraStep2Form.isEditable()"
                                :maxSteps="maxSteps"
                                :error="error"
                            />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                        </form>

                        <form v-else-if="currentStep == 3" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep3Form)" action="POST" enctype='multipart/form-data'>

                            <FormularioCalculadoraStep3
                                :calculadora="calculadoraStep3Form.instance"
                                :editable="calculadoraStep3Form.isEditable()"
                                :maxSteps="maxSteps"
                            />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                        </form>

                        <form v-else-if="currentStep == 4" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep4Form)" action="POST" enctype='multipart/form-data'>

                            <FormularioCalculadoraStep4
                                :calculadora="calculadoraStep4Form.instance"
                                :editable="calculadoraStep4Form.isEditable()"
                                :maxSteps="maxSteps"
                            />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                        </form>
                        
                        <template v-if="tipo == 'projeto'">
                            <form v-if="currentStep == 5" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep5Form)" action="POST" enctype='multipart/form-data'>
                                <FormularioCalculadoraStep5
                                    :calculadora="calculadoraStep5Form.instance"
                                    :editable="calculadoraStep5Form.isEditable()"
                                    :maxSteps="maxSteps"
                                    />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                            </form>

                            <form v-else-if="currentStep == 6" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep6Form)" action="POST" enctype='multipart/form-data'>
                                <FormularioCalculadoraStep6
                                    :calculadora="calculadoraStep6Form.instance"
                                    :editable="calculadoraStep6Form.isEditable()"
                                    :maxSteps="maxSteps"
                                    />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                            </form>

                            <form v-else-if="currentStep == 7" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep7Form)" action="POST" enctype='multipart/form-data'>
                                <FormularioCalculadoraStep7
                                    :calculadora="calculadoraStep7Form.instance"
                                    :editable="calculadoraStep7Form.isEditable()"
                                    :maxSteps="maxSteps"
                                    />
                            <div class="flex justify-end items-center mt-[2rem]">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                            </form>

                            <form v-else-if="currentStep == 8" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep8Form)" action="POST" enctype='multipart/form-data'>
                                <FormularioCalculadoraStep8
                                    :calculadora="calculadoraStep8Form.instance"
                                    :editable="calculadoraStep8Form.isEditable()"
                                    :maxSteps="maxSteps"
                                    />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                            </form>

                            <form v-else-if="currentStep == 9" @submit.prevent="(event)=>handleSubmit(event as any, calculadoraStep9Form)" action="POST" enctype='multipart/form-data'>
                                <FormularioCalculadoraStep9
                                    :calculadora="calculadoraStep9Form.instance"
                                    :editable="calculadoraStep9Form.isEditable()"
                                    :maxSteps="maxSteps"
                                    />
                            <div class="flex justify-end items-center">
                                <button type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem] w-[11.4rem] h-[3.6rem]" @click="goToPreviousStep">Voltar</button>
                                <SubmitButton text="Avançar" :no-border="true" :disabled="processing.getValue()" :error="false"  />
                            </div>
                            </form>
                        </template>
                    </div>
                </template>

                                    
                <template v-else-if="isLoading">
                    <div class="flex flex-col gap-[3rem] mt-[2rem]">
                        <h3 class="text-center font-bold text-[2rem]">Calculando rating...</h3>
                        <Loading />
                    </div>
                </template>
                
                <template v-else>
                    <div v-if="tipo == 'incorporadora'" class="flex flex-col items-center mt-[8rem]">
                        <h3 class="text-[2rem] font-bold">Estamos finalizando o seu rating preliminar</h3>
                        <div class="relative h-[2.5rem] w-full rounded-2xl mt-[2rem] max-w-[40rem] border-2 border-[rgb(0,22,66)] white-bg">
                            <div class="absolute max-w-[38rem] rounded-lg rounded-r-[0rem] h-[2.2rem] dark-blue-bg" :style="progressBar"></div>
                        </div>
                        <div class="flex flex-col items-center mt-[4rem] gap-[4rem]">
                            <div class="flex flex-col">
                                <p class="text-center">Você busca esta análise para financiar algum <strong>empreendimento</strong>? <br>Caso positivo, complete mais algumas perguntas para obter um rating mais preciso.</p>
                                <p class="text-[1.2rem] grey-text text-center">Não será possível cadastrar um empreendimento novamente.</p>
                            </div>
                            <button @click="goToProjeto" type="button" class="dark-blue-bg rounded-2xl text-white p-[.5rem_1.5rem] min-w-[20rem] h-[3.6rem]">Sim, cadastrar agora</button>
                            <div class="light-grey-bg rounded-2xl p-[.5rem_1.5rem] h-[3.6rem] min-w-[20rem] text-center cursor-pointer">
                                <a @click="getHashRating" class="font-bold">Não, gerar rating preliminar</a>
                            </div>
                        </div>
                    </div>                    
                </template>
                
            </div>
        </section>
    </section>
</template>



