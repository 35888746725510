import { IFormInstance, ModelForm, Key } from "../services/Form";


export interface CalculadoraRating {
    nome_incorporadora: string;
    conceito: string;
    nota: string;
    nome_projeto?: string;
}

export interface ICalculadoraStep1 extends IFormInstance {
    email: string;
    nome: string;
    telefone: string;
}

export class CalculadoraStep1Form extends ModelForm {
    instance!: ICalculadoraStep1;
    alwaysEditable = true;
}

export interface ICalculadoraStep2 extends IFormInstance {

    nome_fantasia: string;
    cnpj_incorporadora: string;
    numero_de_obras_entregues: number;
    ano_fundacao: number;
    site: string;
}

export class CalculadoraStep2Form extends ModelForm {
    instance!: ICalculadoraStep2;
    alwaysEditable = true;
}

export interface ICalculadoraStep3 extends IFormInstance {

    ativo_circulante: number;
    caixa_e_aplicacoes_financeiras: number;
    estoques: number;
    ativo_nao_circulante: number;

    passivo_circulante: number;
    financiamentos: number;
    passivo_nao_circulante: number;

    patrimonio_liquido: number;
    arquivo_balanco_patrimonial: File | string;
}

export class CalculadoraStep3Form extends ModelForm {
    instance!: ICalculadoraStep3;
    alwaysEditable = true;
}

export interface ICalculadoraStep4 extends IFormInstance {

    receita_bruta: number;
    lucro_liquido: number;

    arquivo_dre: File | string;

}

export class CalculadoraStep4Form extends ModelForm {
    instance!: ICalculadoraStep4;
    alwaysEditable = true;
}

export interface ICalculadoraStep5 extends IFormInstance {
    nome_projeto: string;
    spe: boolean;
    endereco_completo: string;
    publico_alvo: string;
}

export class CalculadoraStep5Form extends ModelForm {
    instance!: ICalculadoraStep5;
    alwaysEditable = true;
}

export interface ICalculadoraStep6 extends IFormInstance {
    vgv_bruto: number;
    percentual_vendas: number;
    percentual_permuta_fisica: number;
    percentual_entrada: number;
    percentual_parcelado: number;
    numero_parcelas_cliente: number;
    
    // SET AS STRING FOR ASSIGNMENT PROBLEMS -> FormularioCalculadoraStep6.vue
    percentual_estoque: string;
    percentual_financiado: string;
}

export class CalculadoraStep6Form extends ModelForm {
    instance!: ICalculadoraStep6;
    alwaysEditable = true;

    percentages = ['percentual_vendas', 'percentual_estoque', 'percentual_financiado', 'percentual_permuta_fisica', 'percentual_entrada', 'percentual_parcelado'];
    loadInstance(instance: ICalculadoraStep6 | null = null): void {
        if(instance) {
            for(let percentage of this.percentages) {
                if (!instance[percentage as Key]) (instance[percentage as Key] as unknown) = 0;
                (instance[percentage as Key] as unknown) = String(instance[percentage as Key]).replace('.', ',');
            }
        }
        super.loadInstance(instance);
    }


    clean() {
        if(this.instance.percentual_vendas){
            const percentual_vendas = ModelForm.toNumber(this.instance.percentual_vendas)
            if(percentual_vendas > 100)
                this.addError('percentual_estoque', "Valor não pode ultrapassar os 100%")
        }

        if(this.instance.percentual_entrada && this.instance.percentual_parcelado) {
            const percentual_entrada = ModelForm.toNumber(this.instance.percentual_entrada)
            const percentual_parcelado = ModelForm.toNumber(this.instance.percentual_parcelado)        
            if(percentual_entrada + percentual_parcelado > 100)
                this.addError('percentual_financiado', "A soma de % de Entrada e % Parcelado tem que ser menor ou igual a 100%")
        }

        return super.clean()

    }
}

export interface ICalculadoraStep7 extends IFormInstance {
    percentual_permuta_financeira: number;
    percentual_comissoes: number;
}

export class CalculadoraStep7Form extends ModelForm {
    instance!: ICalculadoraStep7;
    alwaysEditable = true;

    percentages = ['percentual_permuta_financeira', 'percentual_comissoes'];
    loadInstance(instance: ICalculadoraStep6 | null = null): void {
        if(instance) {
            for(let percentage of this.percentages) {
                (instance[percentage as Key] as unknown) = String(instance[percentage as Key]).replace('.', ',');
            }
        }
        super.loadInstance(instance);
    }
}

export interface ICalculadoraStep8 extends IFormInstance {
    custo_terreno: number;
    custo_obra: number;
    custo_outorga: number;
    custo_outros: number;
}

export class CalculadoraStep8Form extends ModelForm {
    instance!: ICalculadoraStep8;
    alwaysEditable = true;
}

export interface ICalculadoraStep9 extends IFormInstance {
    data_lancamento_comercial: string;
    data_inicio_obra: string;
    prazo_obra: number;
    prazo_vendas: number;
}

export class CalculadoraStep9Form extends ModelForm {
    instance!: ICalculadoraStep9;
    alwaysEditable = true;
}
