<script lang="ts">
import { defineComponent, inject, PropType } from 'vue'
import { getParams } from '@/services/Browser';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import DesenvolvedorGateway from '@/infra/gateway/desenvolvedorGateway';
import modalService from '@/services/ModalService';
export default defineComponent({
    name: "Login",
    props: {
        next: {type: String, default: ''}
    },
    async created() {
        const response = await this.investidorGateway.getDadosPessoais()
    },
    setup() {
        return {
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
            desenvolvedorGateway: inject<DesenvolvedorGateway>('desenvolvedorGateway')!
        }
    },
    mounted() {
        modalService.modalLoginCadastro('login',
        {
            investidor: this.investidorGateway,
            desenvolvedor: this.desenvolvedorGateway
        },
        true
        )
    }
})
</script>

<template>
</template>