<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType } from '@/Types/InputTypes';
import { ICalculadoraStep6 } from '@/Types/Calculadora'
import { MaskType } from '@/Types/MaskTypes';
import { Money, Percent } from '@/utils/formats'

export default defineComponent({
    name: "FormularioCalculadoraStep6",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep6>, required: true},
        editable: {type: Boolean},
    },
    data() {
        return {
            InputType,
            MaskType,
            Money,
            Percent,
        }
    },
    methods: {
        calcularPercentuais() {
            //  percentual de Estoque
            let percentual_vendido = Number((this.calculadora.percentual_vendas).toString().replace(',','.').split('%')[0])
            let estoque = 0
            if (percentual_vendido < 100) {
                estoque = (100 - percentual_vendido);
            }
            this.calculadora.percentual_estoque = (estoque.toString() + "%").replace('.',',');
            
            // Calcular percentual de Entrada e Parcelado
            let percentual_entrada = Number((this.calculadora.percentual_entrada).toString().replace(',','.').split('%')[0])
            let percentual_parcelado = Number((this.calculadora.percentual_parcelado).toString().replace(',','.').split('%')[0])
            let financiado = 0
            if (percentual_entrada + percentual_parcelado < 100) {
                financiado = (100 - Number(percentual_entrada + percentual_parcelado));
            }
            this.calculadora.percentual_financiado = (financiado.toString() + "%").replace('.',',');
        },
    },
    mounted() {
        this.calculadora.percentual_estoque = "0%";
        this.calculadora.percentual_financiado = "0%";
        this.calcularPercentuais();
    },
    watch: {
        calculadora: {
            handler: 'calcularPercentuais',
            deep: true,
        },
    }
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-3">
        <div>
            <p class="font-bold">Passo 6: Receitas</p>
        </div>

        <div class="grid grid-cols md:grid-cols-2">
            <div>
                <InputField
                    name="vgv_bruto"
                    :is-required="true"
                    v-model:value="calculadora.vgv_bruto"
                    placeholder=" "
                    labelStyles="font-size: 1.4rem"
                    label="VGV Bruto"
                    font-size="15px"
                    class="w-full"
                    :type="InputType.TEXT"
                    :mask="MaskType.DINHEIRO"
                    :col-span="1"/>
                <div class="pl-[3rem] flex flex-col gap-[1rem]">
                    <InputField
                        name="percentual_vendas"
                        :is-required="true"
                        v-model:value="calculadora.percentual_vendas"
                        placeholder=" "
                        labelStyles="font-size: 1.4rem"
                        label="% Vendido"
                        font-size="15px"
                        class="w-full"
                        :un-mask="false"
                        :type="InputType.TEXT"
                        :mask="MaskType.PERCENTUAL"
                        :col-span="1"/>
                    <InputField
                        name="valor_de_permuta_fisica"
                        :is-required="true"
                        v-model:value="calculadora.percentual_permuta_fisica"
                        placeholder=" "
                        labelStyles="font-size: 1.4rem"
                        label="% de Permuta física"
                        font-size="15px"
                        class="w-full"
                        :un-mask="false"
                        :type="InputType.TEXT"
                        :mask="MaskType.PERCENTUAL"
                        :col-span="1"/>
                </div>
            </div>
            <div>
                <div class="flex flex-col gap-[1rem]">
                    <InputField
                    name="percentual_entrada"
                    :is-required="true"
                    v-model:value="calculadora.percentual_entrada"
                    placeholder=" "
                    labelStyles="font-size: 1.4rem"
                    label="% de Entrada"
                    font-size="15px"
                    class="w-full"
                    :un-mask="false"
                    :type="InputType.TEXT"
                    :mask="MaskType.PERCENTUAL"
                    :col-span="1"/>
                    <div class="grid grid-cols-2">
                        <InputField
                            name="percentual_parcelado"
                            :is-required="true"
                            v-model:value="calculadora.percentual_parcelado"
                            placeholder=" "
                            labelStyles="font-size: 1.4rem"
                            label="% Parcelado"
                            font-size="15px"
                            class="w-full"
                            :un-mask="false"
                            :type="InputType.TEXT"
                            :mask="MaskType.PERCENTUAL"
                            :col-span="1"/>
                        <InputField
                            name="numero_parcelas_cliente"
                            :is-required="true"
                            v-model:value="calculadora.numero_parcelas_cliente"
                            placeholder=" "
                            labelStyles="font-size: 1.4rem"
                            label="Número de parcelas"
                            font-size="15px"
                            class="w-full"
                            :type="InputType.TEXT"
                            :mask="MaskType.NUMERO"
                            :col-span="1"/>
                    </div>
                    <div id="percentual_financiado-error" class="error-field text-inadimplente font-small hidden">
                    </div>
                </div>
            </div>
            <div class="flex mt-[1rem]">
                <div class="flex">
                    <p class="text-[1.4rem]">Percentual de Estoque: </p>
                    <p class="ml-[1rem] text-[1.4rem] font-bold">{{ Percent(
                        Number(calculadora.percentual_estoque.toString().replace(',','.').split('%')[0])
                        / 100
                        ) }}</p>
                    
                </div>
                
                <div id="percentual_estoque-error" class="error-field text-inadimplente font-small hidden">
                </div>
            </div>
            <div class="flex flex-col mt-[1rem]">
                <div class="flex">
                    <p class="text-[1.4rem]">Percentual Financiado: </p>
                    <p class="ml-[1rem] text-[1.4rem] font-bold">{{ Percent(
                        Number(calculadora.percentual_financiado.toString().replace(',','.').split('%')[0])
                        / 100) }}</p>
                    
                </div>
                
            </div>
            <div class="flex mt-[1rem]">
                <p class="text-[1.4rem]">VGV Líquido de Permuta: </p>
                <p v-if="calculadora.vgv_bruto && calculadora.percentual_permuta_fisica" class="ml-[1rem] text-[1.4rem] font-bold">
                    {{ 
                    Money(Number(calculadora.vgv_bruto)
                            -
                    ((Number(calculadora.vgv_bruto) * Number(calculadora.percentual_permuta_fisica.toString().replace(',','.').split('%')[0]) / 100)))
                }}</p>
                <p v-else class="ml-[1rem] text-[1.4rem] font-bold">{{Money(0)}}</p>
            </div>
       </div>
    </div>

</template>

<style scoped>

</style>