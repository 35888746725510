<script lang="ts">

import CardInfoSelect from '@/Components/CardInfoSelect.vue';
import GeneralGateway from '@/infra/gateway/generalGateway';
import { inject } from 'vue';
import { ToDate } from '@/utils/formats';
import { getOffset } from '@/services/Browser';

interface DadosAjuda {
    saiba_mais: Array<any>;
    termos_de_uso: Array<any>;
    politica_de_privacidade: Array<any>;
    politica_att: string;
}


export default {
    name: "Ajuda",
    props: {
    },
    components: {
        CardInfoSelect,
    },
    setup() {
        return {
            generalGateway: inject<GeneralGateway>('generalGateway')!,
        }
    },
    data() {        
        return {

            ToDate,
            getOffset,

            dadosAjuda:  undefined as DadosAjuda | undefined,

        };
    },
    methods: {
        async getDadosPaginaAjuda(){
            const response = await this.generalGateway.getDadosPaginaAjuda();
            this.dadosAjuda = response;
        },
    },
    async mounted(){
        await this.getDadosPaginaAjuda();
        if(window.location.href.slice(-1) != '/'){
            const element = document.getElementById(window.location.href.split('#').slice(-1)[0]);
            if(!element) return;
            const top = this.getOffset(element).top;
            window.scroll({
                top: top,
                behavior: 'smooth'
            });
        }
    }
}

</script>

<template>
    <section class="w-full my-[15rem]">
        <div class="ctn home-container">
                <CardInfoSelect
                    anchor="perguntas-frequentes"
                    class="white-bg rounded-3xl pt-[4rem]"
                    :items="dadosAjuda?.saiba_mais"
                    title="Perguntas frequentes"
                    subTitle="Leia aqui as dúvidas frequentes:" />
                <CardInfoSelect
                    anchor="termos-de-uso"
                    class="white-bg rounded-3xl pt-[4rem]"
                    :items="dadosAjuda?.termos_de_uso"
                    title="Termos de uso"
                    subTitle="" />
                <CardInfoSelect
                    anchor="politica-de-privacidade"
                    class="white-bg rounded-3xl pt-[4rem]"
                    :items="dadosAjuda?.politica_de_privacidade"
                    title="Política de privacidade"
                    :subTitle="`Estas políticas poderão ser atualizadas com regularidade e, sempre que realizadas alterações, estas serão publicadas aqui. <strong>Última atualização em <em>${ToDate(dadosAjuda?.politica_att)}</em>.</strong><br><br>
    Nesta Política, explicamos como coletamos, usamos, compartilhamos e protegemos suas Informações Pessoais quando você utiliza os nossos produtos, serviços e o nosso site.`" />
                <CardInfoSelect
                    anchor="fale-conosco"
                    class="white-bg rounded-3xl pt-[4rem]"
                    :items="[]"
                    title="Fale Conosco"
                    :subTitle="`Para otimizar nosso atendimento e garantir a segurança das suas informações, encorajamos o uso da nossa Central de Mensagens para entrar em contato conosco. <br> <br>
                                
                                Através deste canal, dentro do ambiente logado do Painel do Investidor, podemos direcionar sua demanda de forma mais eficiente para a equipe interna adequada, mantendo o registro de todas as interações em um ambiente seguro. <br><br>
                                
                                Acesse a página de mensagens <a class='underline pointer dark-blue font-bold' href='https://urbe.me/dashboard/investidor/pessoal/chat/'  target='_blank'> clicando aqui</a>. <br><br>
                                 
                                Caso encontre dificuldades para acessar a plataforma, por favor, envie um e-mail para: <span class='dark-blue font-bold'><a href='mailto: login@urbe.me'>login@urbe.me</a>.</span>

                                Incorporadoras e parceiros de negócios podem dirigir suas consultas diretamente para: <span class='dark-blue font-bold'><a href='mailto: novosnegocios@urbe.me'>novosnegocios@urbe.me</a>.</span> <br><br>
 
                                Estamos comprometidos em oferecer um atendimento ágil e seguro.`" />
        </div>
    </section>
</template>



