<script lang="ts">
import { PropType } from "vue";

import { Money, Percent } from "@/utils/formats";
import { CountdownInterface, Processing } from '@/Types/General';
import { downloadFileFromUrl, startCountdown } from "@/services/Browser";
import { CaptacaoAberta, DadosCaptacao } from "@/Types/Investimento";
import { isMobile } from "@/services/Mobile";
import ModalService from "@/services/ModalService";
import DownloadButton from './DownloadButton.vue';



export default {
    name: "CardCaptacaoAberta",
    props: {
        captacao: { type: Object as PropType<CaptacaoAberta | DadosCaptacao>, default: {} },
        tipo: { type: String, default: '' },

    },
    components: {
        DownloadButton,
    },
    data() {
        return {
            Money,
            Percent,
            downloadFileFromUrl,
            startCountdown,
            countdown: { value: '' } as CountdownInterface,

            mobile: isMobile(),

            showVerMais: false,

            processing: new Processing(),
        };
    },

    methods: {
        abrirModal() {
            ModalService.mensagemComTitulo("É a rentabilidade anualizada do investimento considerando as projeções de vendas do empreendimento corrigidas pelo INCC projetado para o período do título. Estas estimativas estão sujeitas a variações de mercado e da performance de vendas do empreendimento, podendo não se concretizar.", "TIR - Taxa Interna de Retorno")
        },
        async exportarProspecto() {
            if(this.processing.isBusyOrSetBusy()) return;
            await downloadFileFromUrl(this.captacao.exportar_prospecto_url);
            this.processing.setValue(false);
        }
    },

    async mounted() {
        await this.startCountdown(this.captacao.datahora_fim, this.countdown, 'Expirada');
    }
}

</script>

<template>

    <div class="flex flex-col gap-[1rem] md:gap-[2rem] rounded-3xl bg-[rgba(255,255,255,.33)] p-[3.5rem_4rem] backdrop-blur-[1.5rem] dark-blue"
    :class="tipo == 'home' ? 'mt-[6rem] mb-[6rem]' : 'mt-[6rem]'">
        <div class="flex flex-col md:flex-row md:items-center md:gap-[3rem]">
            <img v-if="tipo=='home'" class="logo-incorporadora filter-dark-blue max-w-[10rem] max-h-[8rem]" :src="captacao.incorporadora_logo">
            <div class="flex flex-col md:flex-row md:items-baseline md:gap-[3rem]">
                <p class="font-bold text-[3rem] md:text-[4rem]">
                    <span v-if="tipo == 'projeto'">Captação aberta</span>
                    <span v-else>{{ captacao.nome_projeto }}</span>
                </p>
                <p class="font-normal text-[2.8rem] md:text-[3rem]" :class="mobile ? 'flex gap-[2rem]' : ''"> {{ captacao.nome_captacao }}
                    <DownloadButton v-if="mobile" @click="exportarProspecto" :loading="processing.getValue()" width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue"/>

                </p>
            </div>
            <DownloadButton v-if="!mobile" :loading="processing.getValue()" @click="exportarProspecto" width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue" classes="ml-auto mr-0"/>
        </div>
        <div class="flex items-center flex-wrap text-[1.5rem] card:block md:text-[1.8rem] lg:grid lg:grid-cols-2">
            <div class="flex items-center">
                <p class="font-bold text-[1.5rem] md:text-[1.8rem]">TIR</p>
                <a class="mt-[-1.5rem] text-[2rem] mr-[1rem] md:text-[2rem] hover:opacity-50 pointer" @click="abrirModal">
                    <i class="fas fa-question-circle" style="transform: scale(0.66);"></i>
                </a>
                <p class="font-bold text-[2rem] card:text-[3rem] md:text-[3.6rem]"> {{ !captacao ? undefined : captacao.tir }} a.a</p>
            </div>
            <div class="flex flex-wrap card:justify-between card:text-[1.8rem] card2:mt-[.5rem]">
                <div>
                    <p><strong>Taxa <span v-if="captacao.rentabilidade_minima == captacao.tir">fixa</span><span v-else>mínima</span></strong> &nbsp; {{ captacao.rentabilidade_minima }} a.a</p>
                    <p><strong>Pagamento</strong> &nbsp; {{ captacao.tipo_pagamento }}</p>
                </div>
                <div>
                    <p><strong>Carência</strong> &nbsp; {{ captacao.carencia }}</p>
                    <p><strong>Vencimento</strong> &nbsp; {{ captacao.vencimento }}</p>
                </div>
            </div>
        </div>

        <div class="flex flex-col md:flex-row mt-[4rem] md:mt-[2rem] gap-[1rem] md:gap-[4rem] items-center">
            <div class="hidden lg:block" :class="captacao.esgotada && tipo != 'home' ? 'w-[22.4rem]' : 'w-[25rem]'">
                <p class="font-bold text-[2rem] md:text-[1.8rem] md:mt-[-2.5rem]">Alvo de captação</p>                        
            </div>

            <div class="w-full">
                <div class="bg-gray-200 relative">
                    <div v-if="!mobile" class="absolute text-[2.4rem] top-[-260%]"
                        :style="captacao.porcentagem == 100 ? 'left: calc(100% - 96px)' : ( captacao.porcentagem <= 16 ? 'left: 0' : ( captacao.porcentagem > 91 && captacao.porcentagem < 100 ? `left: calc(${captacao.porcentagem}% - 82px)` : `left: calc(${captacao.porcentagem}% - 82px)`))
">
                        <p class="text-right tabular-nums">{{ Percent(captacao.porcentagem / 100) }}</p>
                    </div>
                    <div v-else class="absolute text-[2.2rem] top-[-240%]"
                    :style="captacao.porcentagem <= 33 ? 'left: 0' : (captacao.porcentagem == 100 ? 'left: calc(100% - 88px)' : `left: calc(${captacao.porcentagem}% - 76px)`) 
">
                        <p class="text-right tabular-nums">{{ Percent(captacao.porcentagem / 100) }}</p>
                    </div>
                    <div class="dark-blue-bg h-5" :style="`width:${captacao.porcentagem}%`"></div>
                </div>
                <div class="" :class="captacao.esgotada && tipo != 'home' ? 'md:right-[13.7%]' : ''">
                    <p v-if="countdown.value" class="text-[1.8rem] text-right tabular-nums">{{ countdown.value }}</p>
                    <div v-else class="w-[15rem] ml-auto mr-0">
                        <p class="skeleton skeleton-text !h-[20px] mt-[7px]"></p>
                    </div>
                </div>
            </div>

            <div class="flex md:mt-[-2.5rem]">

                <a type="button"
                    :href="!captacao.esgotada || tipo == 'home' ? captacao.url_investimento : undefined"
                    :class="captacao.esgotada && tipo != 'home' ? 'font-bold text-[1.8rem]' : 'w-[15rem] h-[3.2rem] font-medium text-white dark-blue-bg rounded-[4rem] flex items-center justify-center'">
                    <p v-if="!captacao.esgotada" v-html="tipo == 'home' ? 'Ver mais' : 'Investir' "></p>
                    <p v-else>
                        Esgotada
                    </p>
                </a>
            </div>

        </div>
    </div> 
</template>
