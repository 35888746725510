<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType } from '@/Types/InputTypes';
import { ICalculadoraStep7 } from '@/Types/Calculadora'
import { MaskType } from '@/Types/MaskTypes';


export default defineComponent({
    name: "FormularioCalculadoraStep7",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep7>, required: true},
        editable: {type: Boolean},
    },
    data() {
        return {
            InputType,
            MaskType,
        }
    },
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-3">
        <div>
            <p class="font-bold">Passo 7: Abatimentos de Receita</p>
        </div>

        <div class="flex flex-col gap-[1rem]">
            <InputField
                name="percentual_permuta_financeira"
                :is-required="true"
                v-model:value="calculadora.percentual_permuta_financeira"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Percentual de Permuta Financeira"
                font-size="15px"
                class="w-full"
                :un-mask="false"
                :type="InputType.TEXT"
                :mask="MaskType.PERCENTUAL"
                :col-span="1"/>
            <InputField
                name="percentual_comissoes"
                :is-required="true"
                v-model:value="calculadora.percentual_comissoes"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Percentual de Comissões (corretagem)"
                font-size="15px"
                class="w-full"
                :un-mask="false"
                :type="InputType.TEXT"
                :mask="MaskType.PERCENTUAL"
                :col-span="1"/>
        </div>
    </div>

</template>

<style scoped>

</style>