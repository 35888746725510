<script lang="ts">
import {inject} from 'vue';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import CardInvestimento from '@/Components/CardInvestimento.vue';
import CardInvestimentoContainer from '@/Components/CardInvestimentoContainer.vue';
import Loading from '@/Components/Loading.vue';


export default {
    name: "PaginInvestimento",
    components: {
        CardInvestimento,
        CardInvestimentoContainer,
        Loading,
    },
    props: {
        userAccess: {type: Object, default: undefined}
    },
    methods: {

    },
    data() {
        return {
            captacao: undefined as Object | undefined,
            responseData: undefined as Object | undefined,
        }
    },
    setup() {
        return {
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
        }
    },
    async mounted(){
        const {projetoSlug, captacaoSlug, incorporadoraSlug} = this.$route.params
        const response = await this.investidorGateway.getInformacoesInvestimento(projetoSlug as string,captacaoSlug as string,incorporadoraSlug as string);
        if(!response) return;
        const {captacao} = response;
        this.captacao = captacao;
        this.responseData = response;
    }
}

</script>

<template>
    
    <template v-if="captacao">
        <CardInvestimentoContainer
            :captacao="captacao">
            <CardInvestimento
                v-if="captacao"
                :saldo-carteira="responseData?.saldo_carteira"
                :captacao="captacao"
            />
        </CardInvestimentoContainer>
    </template>
    <template v-else>
        <div class="h-screen mx-auto">
            <Loading :size="8"/>
        </div>
    </template>
    
</template>



