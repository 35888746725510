<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { InputType } from '@/Types/InputTypes';
import  InputField from '@/Components/InputField.vue';
import { IInvestimentoTermos,Termos } from '@/Types/Investimento';
import Acordion from '@/Components/Acordion.vue';
import { isMobile } from '@/services/Mobile';

export default defineComponent({
    name: "FormularioInvestimentoTermos",
    props : {
        termosInstance:{type: Object as PropType<IInvestimentoTermos>, required: true},
        termos: {type: Object as PropType<Termos>, required: true}
    },
    components: {
        InputField,
        Acordion
    },
    data() {
        return {
            InputType,

            mobile: isMobile()

        }
    },

})

</script>

<template>
    <div class="mt-main flex flex-col mb-[1.5rem]">
        <div>
            <Acordion 
            :titulo="termos.riscos.titulo"
            :conteudo="termos.riscos.conteudo"
            :open="!mobile ? true : false"
            >
            <template v-slot:body>
                <p class="grey-label text-[1.9rem]" v-html="termos.riscos.conteudo"></p>
            </template>
            </Acordion>

        </div>

        <div>  
            <Acordion 
            :titulo="termos.aceite.titulo"
            :conteudo="termos.aceite.conteudo"
            >
            <template v-slot:body>
                <p class="grey-label text-[1.9rem]" v-html="termos.aceite.conteudo"></p>
            </template>
            </Acordion>
        </div>

        <div>  
            <Acordion 
            :titulo="termos.pep.titulo"
            :conteudo="termos.pep.conteudo"
            >
            <template v-slot:body>
                <p class="grey-label text-[1.9rem]" v-html="termos.pep.conteudo"></p>
            </template>
            </Acordion>
            <div class="flex gap-4 items-center">
                <InputField
                name=""
                :noLabel="true"
                v-model:value="termosInstance.termos"
                :isRequired="true"
                checkBoxColor="light-blue-bg"
                :type="InputType.CHECKBOX">
                </InputField>
                <div class="grey-label font-medium pointer" 
                    @click="termosInstance.termos = !termosInstance.termos">
                    Declaro que li e estou de acordo com os termos acima*
                </div>
            </div>
        </div>

    </div>

</template>

