<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputField from '@/Components/InputField.vue';
import { InputType, FileType } from '@/Types/InputTypes';
import { MaskType } from '@/Types/MaskTypes';
import { ICalculadoraStep2 } from '@/Types/Calculadora';

export default defineComponent({
    name: "FormularioCalculadoraStep2",
    components: {
        InputField,
    },
    props: {
        calculadora: {type: Object as PropType<ICalculadoraStep2>, required: true},
        editable: {type: Boolean},
        error: {type: Object, default: {}}
    },
    data() {
        return {
            InputType,
            MaskType,
        }
    },
})
</script>

<template>
    <div class="step flex flex-col gap-[1rem]" id="step-1">
        <p class="font-bold">Passo 2: Dados da Incorporadora</p>
        <div class="grid grid-cols md:grid-cols-2"> 
            <InputField
                name="nome_fantasia"
                :is-required="true"
                v-model:value="calculadora.nome_fantasia"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Nome da Incorporadora"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :col-span="1"/>

            <InputField
                name="cnpj_incorporadora"
                :is-required="true"
                v-model:value="calculadora.cnpj_incorporadora"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="CNPJ da Incorporadora"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :mask="MaskType.CNPJ" :col-span="1"/>
            
            <InputField
                name="site"
                :is-required="true"
                v-model:value="calculadora.site"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Site da Incorporadora"
                font-size="15px"
                class="w-full"
                :type="InputType.TEXT"
                :col-span="1"/>

            <InputField
                name="numero_de_obras_entregues"
                :is-required="true"
                v-model:value="calculadora.numero_de_obras_entregues"
                placeholder=" "
                labelStyles="font-size: 1.4rem"
                label="Obras entregues"
                font-size="15px"
                :type="InputType.TEXT"
                :mask="MaskType.NUMERO"
                :col-span="1"/>

             <InputField
                v-if="error.field == 'consulta_cnpj'"
                name="ano_fundacao"
                :is-required="true"
                v-model:value="calculadora.ano_fundacao"
                placeholder=" "
                mask="####"
                labelStyles="font-size: 1.4rem"
                label="Ano fundação"
                font-size="15px"
                :type="InputType.TEXT"
                :mask="MaskType.NUMERO"
                :col-span="1"/>
        </div>
    </div>

</template>
