<script lang="ts">
import GoogleMap from './GoogleMap.vue';

import CardInfo from '@/Components/CardInfo.vue';
import CardInfoContainer from '@/Components/CardInfoContainer.vue';
import { Money, Percent, ToDate } from "@/utils/formats";
import { colors } from '@/styles/styles';
import LineChart from '@/Components/LineChart.vue';
import { isMobile } from '@/services/Mobile';
import { Processing } from '@/Types/General';

import CardContainer from '@/Components/CardContainer.vue';
import Card from '@/Components/Card.vue';

import infoIcon from '@/assets/info-icon.svg';
import checkIcon from '@/assets/check.svg';
import medalIcon from '@/assets/medal.svg';

import BaseTable from '@/Components/BaseTable.vue';
import BaseItem from '@/Components/BaseItem.vue';
import MediaMenu from './MediaMenu.vue';

import DownloadButton from './DownloadButton.vue';
import { downloadFileFromUrl, goToUrl } from "@/services/Browser";
import { CaptacaoAberta, DadosCaptacao } from '@/Types/Investimento';
import { PropType } from 'vue';


export default {
    name: 'CardProjeto',
    props: {
        dadosProjeto: { type: Object, default: undefined },
        captacao: { type: Object as PropType<CaptacaoAberta | DadosCaptacao>, default: {} },
        baixarPlanilhaUnidades: {type: Function, default: null},
        baixarPlanilhaProjecoes: {type: Function, default: null},
        baixarFluxoDeCaixa: {type: Function, default: null},

    },
    data() {

        return {
            colors,
            Percent,
            Money,
            ToDate,
            
            mobile: isMobile(),

            isShowing: false,

            processing: new Processing(),
            downloadFileFromUrl,
            goToUrl,
            

            mediasData:[] as Array<any>,

            infoIcon,
            checkIcon,
            medalIcon,
        }
    },
    mounted() {
        if(this.dadosProjeto?.imagens.length > 0) {
            this.mediasData.push(
                {
                    titulo: 'Projeto',
                    imagens: this.dadosProjeto?.imagens,

                }
            )

        }
        if(this.dadosProjeto?.obra_imagens.length > 0) {
            this.mediasData.push(
                {
                    titulo: 'Obra',
                    imagens: this.dadosProjeto?.obra_imagens,
                    tipo: 'obra',
                }
            )
        }
        if(this.dadosProjeto?.video) {
            this.mediasData.push(
                {
                    titulo: 'Vídeo',
                    video: this.dadosProjeto.video_embed,
                    tipo: 'video',
                }
            )
        }
        if(this.dadosProjeto?.desenhos.length > 0) {
            this.mediasData.push(
                {
                    titulo: 'Desenhos',
                    imagens: this.dadosProjeto?.desenhos,
                }
            )
        }
        if(this.mediasData.length > 0) this.mediasData[0].isShowing = true;
    },
    components: { 
        GoogleMap,
        LineChart,
        BaseTable,
        BaseItem,
        CardInfoContainer,
        CardInfo,
        CardContainer,
        Card,
        DownloadButton,
        MediaMenu,
    },
    methods: {
        async exportarRating() {
            if(this.processing.isBusyOrSetBusy()) return;
            await downloadFileFromUrl(this.dadosProjeto?.exportar_pdf_rating_url);
            this.processing.setValue(false);
        },
        async exportarPlanilhaUnidades() {
            if(this.processing.isBusyOrSetBusy()) return;
            await downloadFileFromUrl(this.dadosProjeto?.exportar_planilha_unidades);
            this.processing.setValue(false);
        },
        async exportarPlanilhaProjecoes() {
            if(this.processing.isBusyOrSetBusy()) return;
            await downloadFileFromUrl(this.dadosProjeto?.exportar_planilha_projecoes);
            this.processing.setValue(false);
        },
        async exportarFluxoDeCaixa() {
            if(this.processing.isBusyOrSetBusy()) return;
            await downloadFileFromUrl(this.dadosProjeto?.exportar_fluxo_de_caixa);
            this.processing.setValue(false);
        },
    },
}
</script>

<template>
    <div class="flex flex-col gap-[5rem]">
       <section id="mapa" class="flex flex-col gap-[3rem] md:grid md:grid-cols-2">
            <div v-if="dadosProjeto" class="flex flex-col gap-[.5rem]">
                <GoogleMap :mapUrl="dadosProjeto.url_mapa" :data="[{'latitude': dadosProjeto?.latitude, 'longitude': dadosProjeto?.longitude}]" tipo='location' :showTitle="false" classes=" h-[44rem] md:h-[34rem] rounded-3xl"/>
            </div>
            <div v-else class="skeleton w-full h-[44rem] md:h-[34rem] rounded-3xl"></div>
            <div class="flex flex-col  md:max-h-[34rem]" :class="!dadosProjeto ? 'md:gap-[1rem]' : 'md:justify-between md:gap-[3rem]'">
                
                <div v-if="dadosProjeto" class="md:overflow-y-auto">
                    <p class="mb-[2rem] text-[1.8rem] font-normal grey-label"> {{ dadosProjeto?.apresentacao }}</p>
                </div>
                <div v-else class="skeleton w-full h-full rounded-3xl"></div>
    
                <div v-if="dadosProjeto" class="text-[1.8rem]">
                    <p><a :href="dadosProjeto.url_mapa" target="_blank" class="font-medium pointer">{{ dadosProjeto?.endereco }}</a></p>
                    <p><a v-if="dadosProjeto?.walkability" :href="dadosProjeto.url_mapa" target="_blank" class="pointer"><span class="font-bold">Walkability:</span> {{ Percent(dadosProjeto?.walkability) }}</a></p>
                </div>
                <div v-else>
                    <div v-if="!mobile" class="flex flex-col gap-[1rem]">
                        <div class="skeleton w-[50%] h-[3rem] rounded-3xl">
                            
                        </div>
                        <div class="skeleton w-[30%] h-[3rem] rounded-3xl">
                        </div>
                    </div>
                    <div v-else class="flex flex-col gap-[2rem]">
                        <div class="flex flex-col gap-[.5rem]">
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl"></div>

                        </div>
                        <div class="flex flex-col gap-[.5rem]">
                            <div class="skeleton skeleton-text h-[5rem] rounded-3xl"></div>
                            <div class="skeleton skeleton-text h-[5rem] rounded-3xl"></div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    
        <template id="media" v-if="dadosProjeto">
            <section class="flex flex-col gap-[2rem]">
                 <MediaMenu 
                     v-if="mediasData"
                     :data="mediasData"/>
             </section>
        </template>
        <template v-else>
            <div class="flex flex-col">
                <div class="flex rounded-lg gap-[3rem] md:gap-[.5rem] md:flex">
                    <div class="skeleton skeleton-text rounded-xl rounded-b-[0rem] md:w-[18rem] md:h-[3.5rem]"></div>
                    <div class="skeleton skeleton-text rounded-xl rounded-b-[0rem] md:w-[18rem] md:h-[3.5rem]"></div>
                    <div class="skeleton skeleton-text rounded-xl rounded-b-[0rem] md:w-[18rem] md:h-[3.5rem]"></div>
                </div>
                <div class="skeleton w-full h-[20rem] md:h-[60rem] dark-blue-bg rounded-xl rounded-t-[0rem] md:rounded-tr-[1rem]"></div>
            </div>
        </template>

        <template id="cronogramas" v-if="dadosProjeto">
            <section v-if="dadosProjeto.vendas.length > 1 || dadosProjeto.vendas_projecoes || dadosProjeto.obra.length > 1 || dadosProjeto.obra_projecoes">
                <div v-if="dadosProjeto" class="flex flex-col gap-[3rem]">
                    <div class="flex justify-between">
                        <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Cronogramas</h3>
                        <DownloadButton v-if="!mobile" :text="true" :loading="processing.getValue()" @click="exportarPlanilhaProjecoes" width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue" classes="mr-[1.5rem]"/>
                    </div>
                    
                    <LineChart
                            :height="mobile ? 300 : undefined"
                            :percent="true"
                            :hide-projection="true"
                            :titleLabel="true"
                            :dataSets="[
                                {
                                    label: 'Vendas',
                                    data: dadosProjeto?.vendas,
                                },
                                {
                                    label: 'Projeção de Vendas',
                                    data: dadosProjeto?.vendas_projecoes,
                                    dashed: true,
                                },
                                {
                                    label: 'Obra',
                                    data: dadosProjeto?.obra,
                                    borderColor: colors.lightBlue,
                                    backgroundColor: colors.lightBlue,
                                },
                                {
                                    label: 'Projeção de Obra',
                                    data: dadosProjeto?.obra_projecoes,
                                    dashed: true,
                                    borderColor: colors.lightBlue,
                                    backgroundColor: colors.lightBlue,
                                }
                            ]"
                            unit="month"
                            :min="null"
                            :max="null"
                            :displayYInfo="true"
                            :showYLabel="true"

                        />
                </div>
            </section>

        </template>
        <template v-else>
            <div class="skeleton w-full h-[30rem] rounded-3xl md:h-[58rem]"></div>
        </template>
         
        <template id="viabilidade" v-if="dadosProjeto">
            <section class="flex flex-col gap-[3rem]" v-if="dadosProjeto?.viabilidade.fluxo_de_caixa">
                <div class="flex justify-between">
                    <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Viabilidade Financeira</h3>
                    <DownloadButton v-if="!mobile" :text="true" :loading="processing.getValue()" @click="exportarRating" width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue" classes="mr-[1.5rem]"/>
                </div>
                <div class="rounded-2xl flex flex-col lg:flex-row text-[1.5rem]">
                    <div class="flex flex-col gap-[1rem] lg:gap-[.5rem] lg:flex-row lg:flex-wrap">
                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] order-2 lg:w-[49.7%] lg:order-first">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Receitas</p>
                            </div>
                            <div class="flex flex-col gap-[1rem] md:gap-0 md:text-[1.8rem]">
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-bold">VGV Bruto</p>
                                    <p class="col-span-1 font-bold md:text-right md:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Money(dadosProjeto?.viabilidade.preco_m2) }} / m² </p>
                                    <p class="col-span-1 font-bold text-right">{{ Money(dadosProjeto?.viabilidade.vgv_bruto) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Comercializado</p>
                                    <p class="col-span-1 md:text-right md:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Percent(dadosProjeto?.viabilidade.progresso_vendas_valor) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(dadosProjeto?.viabilidade.total_vendido) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Estoque</p>
                                    <p class="col-span-1 md:text-right md:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Percent(dadosProjeto?.viabilidade.percentual_estoque) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(dadosProjeto?.viabilidade.valor_estoque) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Permuta física</p>
                                    <p class="col-span-1 md:text-right md:pr-[30%] lg:pr-[0%] min-[1120px]:pr-[13%]"> {{ Percent(dadosProjeto?.viabilidade.percentual_permuta_fisica) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(dadosProjeto?.viabilidade.valor_permuta_fisica) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between items-center border-black border-t  md:text-[1.8rem]">
                                <p class="font-bold">VGV Líquido <br class="block min-[400px]:hidden">de Permuta</p>                                
                                <p class="font-bold">{{ Money(dadosProjeto?.viabilidade.vgv_liquido_de_permuta) }}</p>
                            </div>
                        </div>

                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] order-3 lg:w-[49.7%] lg:order-3">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Abatimento de Receitas</p>
                            </div>
                            <div class="flex flex-col gap-[1rem] md:gap-0 md:text-[1.8rem]">
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal md:text-[1.7rem] min-[1061px]:text-[1.8rem]">Permuta financeira</p>
                                    <p class="col-span-1 md:text-right md:pr-[31%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(dadosProjeto?.viabilidade.percentual_permuta_financeira) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(dadosProjeto?.viabilidade.valor_permuta_financeira) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Comissões</p>
                                    <p class="col-span-1 md:text-right md:pr-[31%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(dadosProjeto?.viabilidade.percentual_comissao) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(dadosProjeto?.viabilidade.valor_comissao) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Impostos</p>
                                    <p class="col-span-1 md:text-right md:pr-[31%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(dadosProjeto?.viabilidade.percentual_impostos) }} </p>
                                    <p class="col-span-1 text-right">{{ Money(dadosProjeto?.viabilidade.valor_impostos) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between items-center border-black border-t  md:text-[1.8rem]">
                                <p class="font-bold">VGV Líquido</p>                                
                                <p class="font-bold">{{ Money(dadosProjeto?.viabilidade.vgv_liquido) }}</p>
                            </div>
                        </div>
                        
                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem] order-4 lg:w-[49.7%] lg:order-5">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Custos</p>
                            </div>
                            <div class="flex flex-col gap-[1rem] md:gap-0 md:text-[1.8rem]">
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Terreno</p>
                                    <p class="md:text-right md:pr-[33%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Money(dadosProjeto?.viabilidade.custo_m2_terreno) }} / m² </p>
                                    <p class="text-right">{{ Money(dadosProjeto?.viabilidade.valor_terreno) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Obra</p>
                                    <p class="md:text-right md:pr-[33%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Money(dadosProjeto?.viabilidade.custo_m2_obra) }} / m²</p>
                                    <p class="text-right">{{ Money(dadosProjeto?.viabilidade.valor_obra) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Marketing</p>
                                    <p class="md:text-right md:pr-[33%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(dadosProjeto?.viabilidade.custo_percentual_marketing) }} do VGV </p>
                                    <p class="text-right">{{ Money(dadosProjeto?.viabilidade.valor_marketing) }}</p>
                                </div>
                                <div class="grid grid-cols-2 md:grid-cols-3">
                                    <p class="md:col-span-1 col-span-2 font-medium md:font-normal">Projetos/Aprovação</p>
                                    <p class="md:text-right md:pr-[33%] lg:pr-[0%] min-[1120px]:pr-[16%]"> {{ Percent(dadosProjeto?.viabilidade.custo_percentual_projetos) }} do VGV</p>
                                    <p class="text-right">{{ Money(dadosProjeto?.viabilidade.valor_projetos) }}</p>
                                </div>
                                <div class="grid grid-cols-2">
                                    <p class="font-medium md:font-normal">Outorga onerosa</p>
                                    <p class="text-right"> {{ Money(dadosProjeto?.viabilidade.custo_outorga) }}</p>
                                </div>
                                <div class="grid grid-cols-2">
                                    <p class="font-medium md:font-normal">Outros</p>
                                    <p class="text-right"> {{Money(dadosProjeto?.viabilidade.custo_outros) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between items-center border-black border-t md:text-[1.8rem]">
                                <p class="font-bold">Total</p>                                
                                <p class="font-bold">{{ Money(dadosProjeto?.viabilidade.custo_total) }}</p>
                            </div>
                        </div>
                            

                        <div class="flex flex-col gap-[2rem] rounded-2xl order-first lg:w-[49.7%]">
                            <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem]  md:text-[1.8rem]">
                                <div class="flex justify-between">
                                    <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Ficha Técnica</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Tipo</p>
                                    <p>{{ dadosProjeto.viabilidade.tipo_de_projeto }}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Padrão</p>
                                    <p>{{ dadosProjeto.viabilidade.padrao }}</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p class="flex items-center">Unidades <DownloadButton v-if="!mobile" :text="true" :loading="processing.getValue()" @click="exportarPlanilhaUnidades" width="w-[2.5rem] md:w-[3rem]" height="h-[2.5rem] md:h-[3rem]" backgroundColor="filter-dark-blue" classes="ml-[3rem]"/></p>
                                    <p>{{ dadosProjeto.viabilidade.unidades }} unidades de {{ dadosProjeto.viabilidade.menor_m2.toFixed(0) }} a {{ dadosProjeto.viabilidade.maior_m2.toFixed(0) }} m²</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Área privativa</p>
                                    <p>{{ Money(dadosProjeto.viabilidade.area_privativa, false) }} m²</p>
                                </div>
                                <div class="flex justify-between md:mb-0">
                                    <p>Área construída</p>
                                    <p>{{ Money(dadosProjeto.viabilidade.area_construida, false) }} m²</p>
                                </div>                            
                            </div>
                        </div>


                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem]  md:text-[1.8rem] order-7 lg:w-[49.7%] lg:order-4">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Cronograma</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p>Registro de incorporação</p>
                                <p>{{ ToDate(dadosProjeto.viabilidade.data_ri) }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p>Lançamento</p>
                                <p>{{ ToDate(dadosProjeto.viabilidade.data_lancamento) }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p>Início da obra</p>
                                <p>{{ ToDate(dadosProjeto.viabilidade.data_inicio_obra) }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p>Fim da obra</p>
                                <p>{{ ToDate(dadosProjeto.viabilidade.data_fim_obra) }}</p>
                            </div>                            
                        </div>


                        <div class="light-grey-bg rounded-2xl p-[1.5rem_2rem]  md:text-[1.8rem] order-last lg:w-[49.7%]  lg:order-6">
                            <div class="flex justify-between">
                                <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Indicadores</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Eficiência
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[20rem]">
                                            Área Privativa / Área Construída
                                        </div>
                                    </div>
                                </p>
                                <p>{{ Percent(dadosProjeto.viabilidade.eficiencia) }}</p>                        
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Preço x mercado
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[34rem]">
                                            Preço médio do Empreendimento / Preço médio da Região
                                        </div>
                                    </div>
                                </p>
                                <p>{{ Percent(dadosProjeto.viabilidade.diferenca_percentual_precos_m2) }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p class="flex gap-[.5rem]">
                                    Margem líquida
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[20rem]">
                                            Lucro / VGV Líquido de Permuta
                                        </div>
                                    </div>
                                </p>
                                <p>{{ Percent(dadosProjeto.viabilidade.margem_liquida) }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p class="flex gap-[.5rem]">
                                    ROC
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[15rem]">
                                            Lucro / Custo Total
                                        </div>
                                    </div>
                                </p>
                                <p>{{ Percent(dadosProjeto.viabilidade.roc) }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p class="flex gap-[.5rem]">
                                    ROI
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[18rem] md:w-[20rem]">
                                            Lucro / Exposição de Caixa
                                        </div>
                                    </div>
                                </p>
                                <p>{{ dadosProjeto.viabilidade.roi_exposicao_de_caixa ? Percent(dadosProjeto.viabilidade.roi_exposicao_de_caixa) : '-' }}</p>
                            </div>
                            <div class="flex justify-between md:mb-0">
                                <p class="flex gap-[.5rem]">
                                    TIR
                                    <div class="relative hover-wrapper">
                                    <img :src="infoIcon" class="w-5 h-5"/>
                                        <div class="hover-info indicadores w-[24rem]">
                                            Taxa Interna de Retorno do Incorporador
                                        </div>
                                    </div>
                                </p>
                                <p>{{ dadosProjeto.viabilidade.tir ? Percent(dadosProjeto.viabilidade.tir) + ' a.a' : '-' }}</p>
                            </div>
                            <div class="flex gap-[.5rem] border-black border-t md:mb-0">
                                <p class="font-bold mt-[.3rem] lg:mt-[0rem]">
                                    Rating do Empreendimento
                                </p>
                                <div class="relative hover-wrapper">
                                <img :src="infoIcon" class="w-5 h-5 mt-[.3rem] lg:mt-[.15rem]"/>
                                    <div class="hover-info indicadores w-[32rem]">
                                        Na escala A+, A, A-, B+, B, B-, C+, C ou C-
                                    </div>
                                </div>
                                <p class="mt-[.3rem] bg-[rgba(0,22,66,1)] p-[.1rem_1rem] text-white rounded-xl font-bold ml-auto mr-0">{{ dadosProjeto.viabilidade.rating_projeto }}</p>
                            </div>
                        </div>  
                        <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-5 lg:w-[49.7%] lg:order-7">
                            <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Lucro</p>
                            <p class="font-bold text-[1.5rem] md:text-[1.8rem]">{{ Money(dadosProjeto?.viabilidade.lucro) }}</p>
                        </div>
                        <div class="light-grey-bg rounded-2xl flex items-center justify-between p-[1.5rem_2rem] order-6 lg:w-[49.7%] lg:order-last">
                            <p class="font-bold text-[1.8rem] md:text-[2.2rem]">Exposição de caixa</p>
                            <p class="font-bold text-[1.5rem] md:text-[1.8rem]">{{ Money(dadosProjeto?.viabilidade.exposicao_de_caixa) }}</p>
                        </div> 
                    </div>
                </div>
                
            </section>
        </template>
        <template v-else>
            <div class="flex flex-col gap-[1rem] md:grid md:grid-cols-2">
                <div class="flex flex-col gap-[1rem]">
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[25rem] rounded-2xl"></div>
                </div>
                <div class="flex flex-col gap-[1rem]">
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[17rem] rounded-2xl"></div>
                    <div class="skeleton w-full h-[25rem] rounded-2xl"></div>
                </div>
            </div>
            <div class="flex flex-col gap-[1rem] md:flex-row mt-[-4rem]">
                <div class="skeleton w-full h-[5rem] rounded-2xl"></div>
                <div class="skeleton w-full h-[5rem] rounded-2xl"></div>
            </div>
        </template>
        
        <template id="fluxo_de_caixa" v-if="dadosProjeto">
            <section v-if="dadosProjeto?.viabilidade.fluxo_de_caixa">
                <div class="flex flex-col gap-[3rem]">
                    <div class="flex justify-between">
                        <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Fluxo de Caixa Projetado</h3>
                        <DownloadButton v-if="!mobile" :text="true" :loading="processing.getValue()" @click="exportarFluxoDeCaixa" width="w-[3.5rem] md:w-[4.5rem]" height="h-full" backgroundColor="filter-dark-blue" classes="mr-[1.5rem]"/>
                    </div>
                    <LineChart
                        :zeroLine="true"
                        :min="null"
                        :max="null"
                        :showYLabel="true"
                        :height="mobile ? 300 : undefined"
                        :dataSets="[
                            {
                                label: 'Projeção',
                                data: dadosProjeto.viabilidade.fluxo_de_caixa.grafico_fluxo_de_caixa_projetado,
                                curve: true,
                                dashed: true,
                                backgroundColor: colors.lightBlue,
                                conditionalColor: true,
                            }
                        ]"
                        :displayYInfo="true"
                        unit="month"
                    />
                </div>
                
            </section> 
        </template>
        <template v-else>
            <div class="skeleton w-full h-[30rem] rounded-3xl md:h-[58rem]"></div>
        </template>

        <template id="captacoes" v-if="dadosProjeto">

            <section v-if="dadosProjeto?.captacoes_passadas.length > 0">
                
                <div class="flex flex-col gap-[3rem]">
                    <h3 class="font-medium text-[2.5rem] dark-blue md:text-[3rem]">Captações Anteriores</h3>
                    
                    <div v-if="!mobile"> 
                        <BaseTable
                        :objects="dadosProjeto?.captacoes_passadas"
                        ref="table"
                        cols-class="grid-cols-7"
                        :overflowAuto="false"
                        :labels="{
                            'Rodada': {size: 1, attribute: {name: 'nome', type: 'string'}},
                            'TIR (a.a)': {size:1, attribute: {name: 'tir', type: 'string'}},
                            'Total captado': {size: 1, attribute: {name: 'total_captado', type: 'string'}},
                            'Retorno': {size: 1, attribute: {name: 'valor_bruto',type: 'number'}},
                            'Nº de investidores': {size: 1, attribute: {name: 'numero_investidores', type: 'number'}},
                            'Parcelas': {size: 1, attribute: {name: 'parcelas', type: 'string'}},
                            'Vencimento': {size: 1, attribute: {name: 'vencimento', type: 'string'}},
                        }">
                            <BaseItem v-for="captacao in dadosProjeto?.captacoes_passadas" :size="7" :background="captacao.status == 'Finalizada' ? 'light-grey-bg' : 'bg-white shadow-[1px_3px_3px_1px_rgb(0,0,0,0.12)]'">
                                <div class='text-[2rem] flex font-bold items-center justify-center col-span-1'><p>{{ `${captacao.nome_captacao}`}}</p></div>
                                <div class='font-small flex font-bold items-center justify-center col-span-1'><p>{{ captacao.status == 'Aberta' ? captacao.tir : Percent(captacao.tir) }}</p></div>
                                <div class='font-small flex items-center justify-center'><p>{{ Money(captacao.total_captado)}}</p></div>
                                <div class='font-small flex font-bold items-center justify-center col-span-1 whitespace-nowrap'><p>{{ Money(captacao.valor_bruto) }}</p></div>
                                <div class='font-small flex items-center justify-center col-span-1'><p>{{ captacao.numero_investidores}}</p></div>
                                <div class='font-small flex items-center justify-center col-span-1'><p>{{ captacao.numero_parcelas_pagas }}/{{ captacao.parcelas}}</p></div>
                                <div class='font-small flex items-center justify-center col-span-1 gap-[.5rem]'>
                                    <img v-if="captacao.status == 'Finalizada'" :src="medalIcon" class="filter-dark-blue" /><p>{{ captacao.status != 'Finalizada' ? ToDate(captacao.data_de_previsao_de_pagamento)  : 'Finalizada' }}</p>
                                </div>
        
                            </BaseItem>
        
                            
                        </BaseTable>
    
                    </div>
                    <div v-else class="flex flex-col gap-[1rem]">
                        <div v-for="captacao in dadosProjeto?.captacoes_passadas" class="p-[2rem_1.5rem] rounded-3xl" :class="captacao.status == 'Finalizada' ? 'light-grey-bg' : 'bg-white shadow-[1px_3px_3px_1px_rgb(0,0,0,0.12)]'">
                            <div class="flex justify-between mb-[1rem]">
                                <p class="text-[2rem] font-bold">{{ captacao.nome_captacao }}</p>
                            </div>
                            <div class="grid grid-cols-2 gap-y-[1rem]">
                                <div class="flex gap-[.5rem] text-[1.2rem]">
                                    <p class="font-medium">TIR (a.a)</p>
                                    <p>{{ captacao.status == 'Aberta' ? captacao.tir : Percent(captacao.tir) }}</p>
                                </div>
                                <div class="flex gap-[.5rem] text-[1.2rem] ml-auto mr-0">
                                    <p class="font-medium">Nº de investidores</p>
                                    <p>{{ captacao.numero_investidores }}</p>
                                </div>
                                <div class="flex gap-[.2rem] text-[1.2rem]">
                                    <p class="font-medium">Total captado</p>
                                    <div class="flex">
                                        <p>{{ Money(captacao.total_captado, true, true) }}</p>
                                        <div class="relative hover-wrapper ml-1">
                                            <img :src="infoIcon" class="w-4 h-4"/>
                                            <div class="hover-info w-[11rem] captacoes">
                                                {{ Money(captacao.total_captado) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex gap-[.5rem] text-[1.2rem] ml-auto mr-0">
                                    <p class="font-medium">Parcelas</p>
                                    <p>{{ captacao.numero_parcelas_pagas }}/{{ captacao.parcelas}}</p>
                                </div>

                                <div class="flex gap-[.5rem] text-[1.2rem]">
                                    <p class="font-medium">Retorno</p>
                                    <div class="flex">
                                        <p>{{ Money(captacao.valor_bruto, true, true) }}</p>
                                        <div class="relative hover-wrapper ml-1">
                                            <img :src="infoIcon" class="w-4 h-4"/>
                                            <div class="hover-info w-[10rem] captacoes">
                                                {{ Money(captacao.valor_bruto) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex gap-[.5rem] text-[1.2rem] ml-auto mr-0">
                                    <div>
                                        <p class="font-medium" :class="captacao.status == 'Finalizada' ? 'hidden' : ''">Vencimento</p>
                                    </div>
                                    <div class=flex>
                                        <img v-if="captacao.status == 'Finalizada'" :src="medalIcon" class="filter-dark-blue" /><p>{{ captacao.status != 'Finalizada' ? ToDate(captacao.data_de_previsao_de_pagamento)  : 'Finalizada' }}</p>
                                    </div>
                                </div>                                
                                
                            
                            </div>  
                                
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-else>
            <div v-if="!mobile" class="flex flex-col gap-[1rem]">
                        <div class="flex gap-[3rem] items-center mb-[1.5rem] px-[5rem]">
                            <div class="skeleton skeleton-text rounded-3xl"></div>
                            <div class="skeleton skeleton-text rounded-3xl "></div>
                            <div class="skeleton skeleton-text rounded-3xl "></div>
                            <div class="skeleton skeleton-text rounded-3xl "></div>
                            <div class="skeleton skeleton-text rounded-3xl "></div>
                            <div class="skeleton skeleton-text rounded-3xl "></div>
                            <div class="skeleton skeleton-text rounded-3xl "></div>
                        </div>
                        <div class="skeleton w-full h-[7rem] rounded-3xl"></div>
                        <div class="skeleton w-full h-[7rem] rounded-3xl"></div>
                        <div class="skeleton w-full h-[7rem] rounded-3xl"></div>
                        <div class="skeleton w-full h-[7rem] rounded-3xl"></div>
                    </div>
                    <div v-else class="flex flex-col gap-[1rem]">
                        <div class="skeleton w-full h-[10rem] rounded-3xl"></div>
                        <div class="skeleton w-full h-[10rem] rounded-3xl"></div>
                        <div class="skeleton w-full h-[10rem] rounded-3xl"></div>
                        <div class="skeleton w-full h-[10rem] rounded-3xl"></div>
                    </div>
        </template>
    </div>
</template>
