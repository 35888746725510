<script lang="ts">
import GeneralGateway from '@/infra/gateway/generalGateway';
import CardCaptacaoContainer from '@/Components/CardCaptacaoContainer.vue';
import CardProjetoContainer from '@/Components/CardProjetoContainer.vue';
import CardCaptacaoAberta from '@/Components/CardCaptacaoAberta.vue';
import CardProjeto from '@/Components/CardProjeto.vue';
import CardIncorporadora from '@/Components/CardIncorporadora.vue';
import Loading from '@/Components/Loading.vue';

import CardInputToken from '@/Components/CardInputToken.vue';

import { DadosCaptacao, DadosProjeto } from "@/Types/Investimento";
import { Processing } from '@/Types/General';

import { inject } from 'vue';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import DesenvolvedorGateway from '@/infra/gateway/desenvolvedorGateway';
import { download } from '@/services/Utils';

export default {
    name: "PaginaProjeto",
    data() {
        return {
           dadosProjeto: undefined as DadosProjeto | undefined,
           captacoes: undefined as DadosCaptacao[] | undefined,

           processing: new Processing(),

           loading: true,

           captacoesAbertas: [] as DadosCaptacao[],

           podeAcessarProjeto: false,
           errorMessage: '',
        };
    },
    components: {
    CardCaptacaoContainer,
    CardProjetoContainer,
    CardCaptacaoAberta,
    CardProjeto,
    CardIncorporadora,
    CardInputToken,
    Loading,
},
    setup() {
        return {
            generalGateway: inject<GeneralGateway>('generalGateway')!,
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
            desenvolvedorGateway: inject<DesenvolvedorGateway>('desenvolvedorGateway')!,
        }
    },
    methods: {
        async getDadosPaginaProjeto(incorporadoraSlug: string, projetoSlug: string){
            const response = await this.generalGateway.getDadosPaginaProjeto(incorporadoraSlug, projetoSlug);
            if(!response) return;

            this.captacoesAbertas = response.captacoes_abertas;
            this.dadosProjeto = response;
            this.loading = false;
        },
        async verificaSenhaProjeto(token: string) {
            if (this.processing.getValue() || !this.dadosProjeto) return;
            if(token.replace(/\s+/, "").length < 6) {
                this.errorMessage = 'Token incompleto.';
                this.processing.setValue(false);
                return;
            }
            this.processing.setValue(true)
            const projeto_slug = this.dadosProjeto?.projeto_slug;
            const response = await this.desenvolvedorGateway.postVerificaSenhaProjeto({projeto_slug, token});

            this.podeAcessarProjeto = response.success ? true : false;

            this.errorMessage = response.errors ? response.errors.message : '';

            this.processing.setValue(false);
        },
    },
    async mounted() {
        await this.getDadosPaginaProjeto(this.$route.params.incorporadoraSlug as string, this.$route.params.projetoSlug as string);
        gtag('event', 'view_item', {
          items: [
            {
              item_id: this.dadosProjeto?.id,
              item_name: this.dadosProjeto?.nome_projeto,
              item_brand: this.captacoesAbertas.length > 0 ? this.captacoesAbertas[0].nome_incorporadora : ''
            }
          ]
        });
    },
    
}

</script>

<template>
    
    <template v-if="!loading">
        <template v-if="dadosProjeto?.visualizacao_privada_projeto && !podeAcessarProjeto">
            <CardInputToken title="Informe o token de acesso:" @callback="verificaSenhaProjeto" :error-message="errorMessage" :processing="processing"/>
        </template>
        <template v-else>
            <div class="bg-white">
                <CardCaptacaoContainer
                    tipo="projeto"
                    :captacoesAbertas="captacoesAbertas?.length > 0"
                    :bgImage="dadosProjeto?.capa"
                    :logoIncorporadora="dadosProjeto?.logo_incorporadora"
                    :nomeProjeto="dadosProjeto?.nome_projeto"
                    :cidadeProjeto="dadosProjeto?.cidade_projeto">
                    <CardCaptacaoAberta
                        v-for="captacao in captacoesAbertas" 
                        :captacao="captacao"
                        tipo="projeto" 
                        :nomeProjeto="dadosProjeto?.nome_projeto"
                    />
                </CardCaptacaoContainer>
                <div class="ctn home-container">
                    <CardProjetoContainer
                        :siteProjeto="dadosProjeto?.site"    
                    >
                        <CardProjeto
                            :dadosProjeto="dadosProjeto"
                        />
                    </CardProjetoContainer>
                    <CardIncorporadora :dadosProjeto="dadosProjeto" />
                </div>
            </div>
        </template>
    </template>
        <template v-else>
            <div class="h-screen mx-auto">
                <Loading :size="8"/>
            </div>
        </template>

</template>

<style scoped>

.numbers {
    background: #001A42;
    height: 2px;
    width: 12px;
    border-radius: 2px;
}
</style>
