<script lang="ts">

export default {
    name: 'CardProjetoContainer',
    props: {
        siteProjeto: {type: String, default: ''},
    }
}
</script>

<template>
    <div class="px-[1rem]">
        <div class="py-[3rem] h-full">
            <div class="flex flex-wrap gap-[1.5rem] justify-between items-center">
                <h2 class="font-medium dark-blue text-[3rem] md:text-[3.6rem]"> O Empreendimento </h2>
                
                <a target="_blank" class="rounded-full light-blue-bg h-[4rem] flex justify-center items-center w-[14rem] text-[1.8rem] text-slate-800" :href="siteProjeto">Conhecer +</a>
            </div>
            <div class="border-[.1rem] border-solid border-[#001a42] my-[1.5rem]"></div>
            <slot />
            
        </div>
    </div>
</template>